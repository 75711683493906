<template>
    <el-dialog title="添加文件" :visible.sync="confitionDialog" append-to-body custom-class='class-dialog' width="600px" @close="onCancel" :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside AddDialog">
            <div class="sunbox">
                <div class="sunbox-text">添加文件</div>
                <div class="sunbox-content videoList">
                    <div class="duibiaovideo" v-if="ExcelData.id">
                        {{ExcelData.file_client_name}}
                        <!-- <audio ref="VideoDetailUrl" controls v-if="ExcelData.id"><source :src="ondownloadFileLog(ExcelData.id)" type="audio/mp3"></audio> -->
                    </div>
                    <el-upload class="upload-demo" :action="$api.upload + '?token=' + '7ce563cc4f49544991de6ed0ac743bbe'" :headers="{'token':'7ce563cc4f49544991de6ed0ac743bbe'}" alt="" ref="upload" show-file-listapi :on-success="onSuccessupload" :before-upload="beforeAvatarUpload">
                        <el-button type="primary" style="po" icon="el-icon-upload">上传文件</el-button>
                    </el-upload>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            confitionDialog: false,
            ExcelData: {},//上传的列表
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
        };
    },

    mounted() {
        // console.log(this.token)
    },

    methods: {
        //接收参数弹窗
        onAddDialog() {
            this.confitionDialog = true
        },
        //文件上传成功
        onSuccessupload(res, file, fileList) {
            this.ExcelData = {}//音频地址
            if (res.code == 200) {
                setTimeout(() => {
                    this.$message({ message: '上传成功', type: 'success' })
                    this.ExcelData = res.data//音频地址
                }, 1000);
            } else {
                this.$refs.upload.uploadFiles.pop()//上传失败后移出刚上传的文件
                this.$message.error(res.message);
            }
        },
        //文件上传控制大小
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 100;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 100MB!');
            }
            return isLt2M;
        },
        //取消
        onCancel() {
            this.confitionDialog = false
            this.ExcelData = {}//上传的列表
        },
        //提交
        onSubmit() {
            if (!this.ExcelData.id) { this.$message({ message: '文件不能为空', type: 'warning' }); return }
            var param = {
                source_file_id: this.ExcelData.id,//文件链接
            }
            // console.log(this.ExcelData)
            this.$service.post(this.$api.add_task,param, (res)=> {
                if(res.code == '200'){
                    // console.log(res.data)
                    this.$message({message:'添加成功',type: 'success'})
                    this.$parent.ongetPartybList()
                    this.onCancel()
                }
            })
        },
    },



    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.AddDialog {
    .videoList {
        display: flex;
        align-items: center;
        position: relative;

        video {
            width: 200px;
        }
        .el-upload-list {
            display: none;
        }
    }
}
</style>
<template>
    <div class="division">
        <!-- <div class="padd20 borbut20" style="position: relative;">
            <div class="topSearchSou" style="height:75px">
                <div class="searchdan">
                    <div class="title">运营师：</div>
                    <el-input v-model="head_of_operations" placeholder="请输入运营师"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">产品名称：</div>
                    <el-input v-model="product_name" placeholder="请输入产品名称"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">剪辑师：</div>
                    <el-input v-model="editor_name" placeholder="请输入剪辑师"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">客户名称：</div>
                    <el-input v-model="customer_name" placeholder="请输入客户名称"></el-input>
                </div>
                <div class="searchdan time">
                    <div class="title">制作日期：</div>
                    <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="searchdan">

                </div>
            </div>
            <div class="RightBotton">
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' style="margin-left:0;">重置数据</el-button>
            </div>
        </div> -->

        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">对账列表</div>
                <el-button type="primary" id="Allocation" @click="onAddDialog($event)" class="yinyinga">添加数据</el-button>
                <div class="lvtotal">
                    <!-- <a class="download" @click="onExport" target="_blank">导出<i class="el-icon-download"></i></a> -->
                    总条数：{{lvtotal}} 条
                </div>
            </div>
            <el-table ref="filterTable" :data="PartybList" style="width: 100%">
                <el-table-column prop="product_name" label="产品名">
                    <template slot-scope="scope">
                        {{scope.row.source_file.file_name}}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="上传时间"></el-table-column>
                <el-table-column prop="id" label="上传结果">
                    <template>
                        已完成
                    </template>
                </el-table-column>
                <el-table-column prop="click_upload_head_name" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="success" @click="onDownload(scope.row)" style="margin-left:0px">筛选结果下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
            </div>
        </div>
        <!-- <VideoDialog ref="VideoDialog"></VideoDialog>
        <LookVideo ref="LookVideo" :VideoChildList="VideoChildList"></LookVideo> -->
        <AddDialog ref="AddDialog"></AddDialog>
    </div>
</template>

<script>
// import VideoDialog from './VideoDialog.vue'
// import LookVideo from "../commonSearch/lookVideo.vue";
import AddDialog from './AddDialog.vue'
export default {
    data() {
        return {
            paramData: {},//表格参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1,//表格当前第几页
            PartybList: [],//乙方分工列表
            VideoChildList: [],//查看视频列表

            pickerTime: [],//日期参数
            product_name: '',//产品名称
            editor_name: '',//剪辑师名称
            customer_name: '',//客户名称
            head_of_operations: '',//运营师


        };
    },

    components: {
        AddDialog,
        // LookVideo,
        // VideoDialog
    },

    mounted() {
        this.ongetPartybList()
    },

    methods: {
        //去复审
        // toUserFushen() {
        //     window.open('https://user.erlangcha.com/workList/createIng')
        // },
        //导出
        // onExport() {
        //     var UrlParam = '?export=1' +
        //         '&token=' + (JSON.parse(localStorage.getItem('TZ-USER')).user_token) +
        //         '&per_page=9999' +
        //         '&head_of_operations=' + (this.head_of_operations ? this.head_of_operations : '') + //运营师
        //         '&product_name=' + (this.product_name ? this.product_name : '') + //产品名称
        //         '&editor_name=' + (this.editor_name ? this.editor_name : '') + //剪辑师名称
        //         '&customer_name=' + (this.customer_name ? this.customer_name : '') + //客户名称
        //         '&start_created_at=' + (this.paramData.start_created_at ? this.dateFtt("yyyy-MM-dd", this.start_created_at) : "") +
        //         '&end_created_at=' + (this.paramData.end_created_at ? this.dateFtt("yyyy-MM-dd", this.end_created_at) : "")
        //     window.open(this.$api.getPartybList + UrlParam)
        // },
        //下载
        onDownload(item) {
            window.open(this.$api.download_result + "?source_file_id=" + item.source_file_id + "&token=" + JSON.parse(localStorage.getItem('TZ-USER')).admin_token)
        },
        //上传视频文件
        onVideoDialog(item) {
            this.$refs.VideoDialog.onVideoDialog(item)
        },
        //添加文件
        onAddDialog() {
            this.$refs.AddDialog.onAddDialog()
        },
        //商品搜索
        onSearch() {
            this.paramData = {
                head_of_operations: this.head_of_operations,//运营师
                product_name: this.product_name,//产品名称
                editor_name: this.editor_name,//剪辑师名称
                customer_name: this.customer_name,//客户名称
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
            }
            this.paramPage = 1
            this.ongetPartybList()
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetPartybList()
        },
        //数据列表
        ongetPartybList() {
            this.ErShopList = []
            var param = this.paramData
            param.page = this.paramPage
            param.per_page = this.per_page
            this.$service.get(this.$api.getTask, param, (res) => {
                if (res.code == '200') {
                    // console.log(res.data)
                    this.PartybList = res.data.data
                    this.lvtotal = res.data.count;
                }
            })
        },
    },


    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.division {
    .topSearchSou {
        width: calc(100% - 150px);
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        .searchdan {
            &.time {
            }
        }
    }
    .headName {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        margin-bottom: 5px;
    }
}
.headnamePla {
    max-width: 355px;
}
</style>